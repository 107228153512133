import React from 'react'
import './this.scss'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return <>
        <footer className='index'>
            <div className='description'>
                <Link to="/" onClick={() => window.location.replace("/#Home")}><img src="/assets/images/logo.svg" alt="" /></Link>
                <p>At Hugh-Dev, we are committed to delivering outstanding websites that help our clients achieve their goals.  Get in touch today to learn more about how we can help your business succeed online.</p>
            </div>
            <div className='info'>
                <h2>Contact Information</h2>
                <a className='underline' href="tel:+1 (703) 323-7766"><Icon icon="material-symbols:call-outline" />+1 (703) 323-7766</a>
                <a className='underline' href="mailto:contact@hugh-dev.com"><Icon icon="ic:outline-email" />contact@hugh-dev.com</a>
                <div className="socials">
                    <Link to="https://www.facebook.com/profile.php?id=100088210045151" target='_blank'><Icon icon="uil:facebook-f" /></Link>
                    <Link to="https://twitter.com/JoonyPuny" target='_blank'><Icon icon="ri:twitter-fill" /></Link>
                    <Link to="https://instagram.com/joonypuny?igshid=MmJiY2I4NDBkZg==" target='_blank'><Icon icon="mdi:instagram" /></Link>
                    <Link to="https://www.linkedin.com/in/joon-hugh-8289911b6" target='_blank'><Icon icon="ri:linkedin-box-fill" /></Link>
                </div>
            </div>
            <div className='links'>
                <h2>Quick Links</h2>
                <ul className="quick-links">
                    <li className='underline point' onClick={() => window.location.replace("/#Services")}>Services</li>
                    <li className='underline point' onClick={() => window.location.replace("/#AboutUs")}>About Us</li>
                    <li className='underline point' onClick={() => window.location.replace("/#Clients")}>Clients</li>
                    <li className='underline point' onClick={() => window.location.replace("/#Contact")}>Contact</li>
                    <li className='underline point' onClick={() => window.location.replace("/#FAQ")}>FAQ</li>
                </ul>
            </div>
            <div className='newsletter'>
                <h2>Newsletter</h2>
                <form action="#" onSubmit={e => e.preventDefault()}>
                    <input type="email" name="email" required placeholder='Enter your email' />
                    <input className='point' type="submit" value='➜'/>
                </form>
            </div>
        </footer >
        <footer id='copyright'>Copyright © 2023 Hugh-Dev | <Link className='underline' to="/privacy-policy">Privacy Policy</Link> | All Rights Reserved </footer>
    </>
}
