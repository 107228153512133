import React, { useState } from 'react'
import './this.scss'

export default function Faq() {

    const dataCollection = [
        {
            question: "How long does it take to develop a website?",
            answer: "The length of time it takes to develop a website can vary greatly depending on the complexity of the project, the features required, and the size of the website. A simple brochure website can be completed in a matter of days, while a large e-commerce website can take several weeks. Hugh-Dev strives to provide our clients with high-quality, custom websites within a reasonable timeframe. We work closely with our clients to determine their specific needs and goals for their website, and we provide regular updates throughout the development process to ensure that the website meets their expectations."
        }, {
            question: "What is Search Engine Optimization?",
            answer: "Search Engine Optimization (SEO) is the process of optimizing a website to improve its ranking and visibility in search engine results pages. It involves various techniques, including keyword research, on-page optimization, link building, and content creation, among others. At Hugh-Dev, we specialize in providing effective SEO strategies to help businesses increase their online visibility and reach their target audience. Whether you're looking to improve your search engine rankings or drive more traffic to your website, we have the expertise and experience to help you achieve your goals."
        }, {
            question: "What is Full-Stack Development?",
            answer: "Full-Stack Development is the practice of developing both the client-side (front-end) and server-side (back-end) of a website or application. A Full-Stack Developer is capable of working with both the technical aspects of a website, as well as the design and user experience. At Hugh-Dev, we have a team of skilled Full-Stack Developers who can bring your website to life."
        }, {
            question: "Can you help with website hosting and domain registration?",
            answer: "Yes, we offer website hosting and domain registration services as part of our web development solutions. We understand the importance of having a reliable and secure website that is always available to your customers, and we work with industry-leading providers to ensure that your website is hosted on a secure and fast server. We can also help you choose and register a domain name that aligns with your brand and business goals."
        }, {
            question: "What kind of support do you offer after the website launch?",
            answer: "After the launch of your website, our support doesn't end there. We offer ongoing support and maintenance to ensure that your website is running smoothly and efficiently. Our team is always available to address any questions or concerns you may have. Let us handle the technical details so you can focus on growing your business."
        }
    ]

    const [accordion, setActiveAccordion] = useState(-1);

    function toogleAccordion(index) {
        index === accordion ? 
            setActiveAccordion(-1)
            :
            setActiveAccordion(index)
    }

    return <section id='FAQ' data-aos="fadeIn" data-aos-duration="500" data-aos-once="true" data-aos-easing="cubic-bezier(.17,.67,.71,1)">
        <h1>Frequently Asked Questions</h1>
        {dataCollection.map((item, index) =>
            <div className="accordion" key={`accordion-body-${index}`}>
                <p onClick={() => toogleAccordion(index)} className={accordion === index ? 'question active' : "question"}>
                    {item.question}
                    <span>
                        { accordion === index ? '-' : '+' }
                    </span>
                </p>

                <div className={accordion === index ? "active answer" : "answer"} >
                    <div>
                        <p>{item.answer}</p>
                    </div>
                </div>
            </div>
        )}
    </section>
}
