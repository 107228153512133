import React from 'react'
import './this.scss';

export default function C_images() {
    return <section id="SatisfiedClients" className='fadeIn'>
        <h2>Over 15+ satisfied clients</h2>
        <picture>
            <source srcSet='/assets/images/c-images.avif' />
            <source srcSet='/assets/images/c-images.webp'/>
            <img className="desktop" src="/assets/images/c-images.jpg" alt="Clients' Logos" />
        </picture>
        <picture>
            <source srcSet='/assets/images/c-images-mobile.avif' />
            <source srcSet='/assets/images/c-images-mobile.webp' />
            <img className="mobile" src="/assets/images/c-images-mobile.jpg" alt="Clients' Logos" />
        </picture>
    </section>
}
