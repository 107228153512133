import React, { useState } from 'react';
import './this.scss'

export default function Form() {
    const [formName,    updateFormName]    = useState('')
    const [formTel,     updateFormTel]     = useState('')
    const [formEmail,   updateFormEmail]   = useState('')
    const [formMessage, updateFormMessage] = useState('')
    const [formStatus,  setFormStatus]     = useState('Available')
    const [formError,   setFormError]      = useState('')

    function onAnimEnd(e) {
        if (e.animationName === 'formFade') {
            const formContainer = document.querySelector('.formContainer')
            document.querySelector('#ContactForm').remove()
            formContainer.style.height = '300px'
            if (formStatus === 'Success') formContainer.innerHTML = `<div class='center formResponse fadeIn'><h1>Email sent.</h1><p>We will reply soon.</p></div>`
            else formContainer.innerHTML = `<div class='center formResponse fadeIn'><h1>We're having issues.</h1><p>Try again later or send us an email at contact@hugh-dev.com</p></div>`
            setTimeout(() => formContainer.style.height = '', 500);
        }
    }

    async function formSubmit () {
        // Required fields
        if (!formName || !formEmail || !formMessage) return setFormError('Missing required fields')
        // Validate phone# is phone#
        if (formTel) if (!/^(\+?\d{1,3}-?)?(\d{3}-?){2}\d{4}(\/\d{3,4})?$/.test(formTel)) return setFormError('Invalid phone number')
        // Validate email is email
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formEmail)) return setFormError('Invalid email')
        setFormError('')
        setFormStatus('Pending')
        document.getElementById('submitForm').setAttribute('disabled', '')
        const formFetch = await fetch('https://worker.hugh-dev.com/form', {
            method: "post",
            headers: {
                name: formName,
                phone: formTel,
                email: formEmail,
                message: formMessage
            }
        }).then(req => req.status)
        document.querySelector('.formContainer').style.height = `${document.querySelector('.formContainer').offsetHeight}px`

        if (formFetch === 200) setFormStatus('Success')
        else setFormStatus('Unavailable')
        document.querySelector('#ContactForm').classList.add('formFade')
    }

    return <section id="Contact" data-aos="fadeIn" data-aos-duration="500" data-aos-once="true" data-aos-easing="cubic-bezier(.17,.67,.71,1)">
        <div className='formContainer center'>
            <form id="ContactForm" onSubmit={e => e.preventDefault()} onAnimationEnd={onAnimEnd}>

                <div className='center formHeader'>
                    <h1>Request a Quote</h1>
                    <p>To request a quote or arrange a meeting, contact us via email or fill the out form and we will get back to you.</p>
                </div>
                <input type="text" placeholder='Your Name' required onInput={e => updateFormName(e.target.value)} />
                <input type="tel" placeholder='Phone Number (Optional)' pattern={new RegExp(/^(\+?\d{1,3}-?)?(\d{3}-?){2}\d{4}(\/\d{3,4})?$/).toString()} onInput={e => updateFormTel(e.target.value)} />
                <input type="email" id='email' placeholder='Email Address' required pattern={new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).toString()} onInput={e => updateFormEmail(e.target.value)} />
                <textarea name="msg" id="msg" cols="30" rows="5" placeholder='Your Message' required onInput={e => updateFormMessage(e.target.value)} />
                {
                    formError ?
                        <div className='center formError'><p>{formError}</p></div>
                        :
                        <></>
                }
                <div className="center">
                    <input id='submitForm' type='submit' onClick={formSubmit} value={'Send now'} />
                </div>

            </form>
        </div>
    </section>
}
