/* 
    Todo - Remake without swiper
*/
import React from 'react';
import './this.scss'
// import Swiper and modules styles
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';
import './swiper.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
SwiperCore.use([Navigation, Pagination])

export default function Testimonial() {

    const testimonials = [
        {   /* Yong Hur / SMTechnology */
            imgSrc: "/assets/images/client-1.webp",
            name: "Yong Hur",
            company: "SMTechnology",
            description: "I appreciate the attention to detail that this web development team brought to my project. They took the time to understand my brand and my goals, and the end result was a website that perfectly reflected my vision. They were also responsive and easy to work with. I would definitely hire them again in the future."
        }, 
        {   /* Rev Singh / RΞV3 Studios */
            imgSrc: "/assets/images/client-2.webp",
            name: "Rev Singh",
            company: "RΞV3 Studios",
            links: [ 
                { title: 'Website', url: "https://rev3studios.com", icon: '/assets/icons/link.svg' }
            ],
            description: "As a digital marketing expert, I've worked with countless web development agencies over the years, but Hugh-Dev stands out as one of the best. Their team's technical knowledge, attention to detail, and commitment to delivering exceptional results are unmatched. I highly recommend them to anyone in need of top-notch web development services."
        }, 
        {   /* Sam Lee */
            imgSrc: "/assets/images/client-3.webp",
            name: "Sam Lee",
            links: [
                { title: "Instagram", url: "https://www.instagram.com/samiflyyy", icon: "/assets/icons/instagram_black.svg" },
                { title: "TikTok", url: "https://www.tiktok.com/@samiflyyy", icon: "/assets/icons/tiktok.svg" }
            ],
            description: "Working with this web development team was a breeze. They were responsive, patient, and worked hard to deliver a website that met my unique needs. They also provided ongoing support after the launch, which was a huge relief. I would definitely work with them again and highly recommend them to others."
        }
    ]

    return <section id='Clients' data-aos="fadeIn" data-aos-duration="500" data-aos-once="true" data-aos-easing="cubic-bezier(.17,.67,.71,1)">
        <h1>Hear From Our Clients</h1>
        {/* Init swiper */}
        <Swiper
            cssMode={true}
            loop={true}
            pagination={{ clickable: true }}
            navigation={true}
        >
            
            {/* generate slides from testimonials array */}
            {testimonials.map((client, index) =>
                <SwiperSlide key={`testimonial-slide-${index}`}>
                    <div className="testimonial-card">
                        <div className="client-img">
                            <img src={client.imgSrc} alt={client.name} />
                        </div>
                        <div className="testimonial">
                            <p className='quote'>❝</p>
                            <p className='description'>{client.description}</p>
                            <div className="client-info">
                                {client.name}
                                {/* non-breaking space used below to fix spacing; not an invisible character */}
                                {client.company ? <span> - {client.company}</span> : <></>}
                                {
                                    client.links?.map((link, index) =>
                                        <a href={link.url} key={`${client.name}-link-${index}`} title={link.title} target='_blank' rel='noreferrer'>
                                            <img src={link.icon} alt='' />
                                        </a>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            )}

        </Swiper>
    </section>
}
