import React, { useEffect, useRef } from "react";
import './this.scss'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
export default function Home() {
    const topContainer = useRef();

    useEffect(() => {
        // To make sure page starts from the top
        document.body.addEventListener('onload', () => {
            topContainer.current.scrollIntoView({ block: "start", behavior: 'smooth' });
        })
    });

    return <section id='Home' ref={topContainer}>
        <div>
            <h1 className="swipeRight">Unleash the Power of SEO & Marketing with Hugh-Dev</h1>
            <p className="swipeRight">Elevate your brand and embrace the digital transformation with our tailored strategies and creative excellence</p>
            {/* Button */}
            <Link to="/" className='swipeRight' onClick={() => window.location.replace("/#Contact")}>
                <Icon icon="material-symbols:mail-outline" />Contact Us
            </Link>
        </div>
    </section>
}
