import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import { Icon } from '@iconify/react';
import Index from './routes/index/Index';
import PrivacyPolicy from './routes/privacypolicy/PrivacyPolicy';
import './App.scss';
import './styles/animation.scss'

export default function App() {

    useEffect(() => {
        // When the user scrolls down 500px from the top of the document, show the back-to-top button
        window.onscroll = () => {
            let topButton = document.getElementById("backToTop");
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 500) {
                topButton.style.opacity = "1";
                topButton.style.visibility = "visible";
            } else {
                topButton.style.opacity = "0";
                topButton.style.visibility = "hidden";
            }
        }

        // For Chrome, Firefox, IE and Opera
        window.onload = () => {
            document.body.scrollIntoView({ block: "start" })
            document.querySelector(":root").style.scrollBehavior = "smooth";
        }

        document.body.scrollTop = 0; // For Safari

    }, []);

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return <Router>
        <button onClick={topFunction} id="backToTop" title="Back To Top"><Icon icon="material-symbols:arrow-back-ios-new-rounded" rotate={1} /></button>
        <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    </Router>
}