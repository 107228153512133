import React, { useEffect } from 'react'
// Components
import NavBar from '../../components/navbar/NavBar';
import Hero from '../../components/hero/Hero';
import CImages from '../../components/company-images/C_images';
import Services from '../../components/services/Services';
import AboutUs from '../../components/aboutus/AboutUs';
import Testimonial from '../../components/testimonial/Testimonial';
import Form from '../../components/form/Form';
import Faq from '../../components/faq/Faq';
import Footer from '../../components/footer/Footer';
// Animate on scroll
import AOS from 'aos';
// Styles
import './this.scss'
import 'aos/dist/aos.css';

export default function Page_structure() {
  useEffect(() => {
    AOS.init();
  })
  return <>
    <NavBar />
    <Hero />
    <main className='index'>
      <CImages />
      <Services />
      <AboutUs />
      <Testimonial />
      <Form />
      <Faq />
    </main>
    <Footer />
  </>
}
