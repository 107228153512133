import React from 'react';
import './this.scss'
import { Link } from 'react-router-dom';

export default function Services() {

    const serviceData = [
        {
            image: '/assets/images/seo-card.webp',
            title: "Search Engine Optimization",
            description: "Boost your online visibility and attract more customers with our SEO services. Optimize your website to reach your target audience and drive more traffic to your site."
        }, {
            image: '/assets/images/design-card.webp',
            title: "Website Design",
            description: "Deliver an exceptional user experience with our intuitive user interface design. Create a seamless and user-friendly interface across all devices to help visitors find what they need quickly and efficiently."
        }, {
            image: '/assets/images/performance-card.webp',
            title: "Performance",
            description: "Optimize your website's page load speed, mobile performance, server response time, and code to ensure that your website runs smoothly and efficiently."
        }
    ]

    return <section id='Services' data-aos="fadeIn" data-aos-duration="500" data-aos-once="true" data-aos-easing="cubic-bezier(.17,.67,.71,1)">
        <h1>Our Services</h1>
        <div className="container">
            {serviceData.map((service, index) =>
                <div className="card" key={`service-card-${index}`}>
                    <img src={service.image} alt={service.title} />
                    <div>
                        <h2>{service.title}</h2>
                        <p>{service.description}</p>
                    </div>
                </div>
            )}
        </div>
        <Link to='/' onClick={() => window.location.replace("/#Contact")}>Get a quote</Link>
    </section>
}