import React, { useEffect } from 'react';
import './this.scss';
import { Link } from 'react-router-dom';

export default function NavBar() {
    function toggleMenu() {
        document.querySelector('nav').classList.toggle('active')
        document.querySelector('#navDim').classList.toggle('active')

        if (document.querySelector('#navDim').classList.contains('active')) {
            document.querySelector(':root').style.overflowY = 'hidden'
        } else {
            document.querySelector(':root').style.overflowY = 'unset'
        }
    }

    function isMobile () {
        return window.innerWidth <= 800
    }

    useEffect(() => {
        // add toggleMenu to navDim's onClick
        document.querySelector('#navDim').onclick = () => {
            toggleMenu()
        }

        // toggle off when full-size
        window.addEventListener('resize', () => {
            if (window.innerWidth > '800') {
                document.querySelector('nav').classList.remove('active')
                document.querySelector('#navDim').classList.remove('active')
                document.querySelector(':root').style.overflowY = 'unset'
            }
        })
    })

    return <>
        {/* dim/blur below mobile nav menu */}
        <div id="navDim" /> 
        
        <nav className='navbar'>
            <Link to="/" className='fadeIn'><img src="./assets/images/logo.svg" alt="LOGO" /></Link>

            {/* hamburger menu */}
            <div className="menu fadeIn" onClick={toggleMenu}>
                <div />
                <div />
                <div />
            </div>

            {/* links */}
            <ul className="">
                <li className='underline fadeIn'><Link to="/" onClick={() => {if (isMobile()) toggleMenu(); window.location.replace("/#Services")}}>Services</Link></li>
                <li className='underline fadeIn'><Link to="/" onClick={() => {if (isMobile()) toggleMenu(); window.location.replace("/#AboutUs")}}>About Us</Link></li>
                <li className='underline fadeIn'><Link to="/" onClick={() => {if (isMobile()) toggleMenu(); window.location.replace("/#Clients")}}>Clients</Link></li>
                <li className='underline fadeIn'><Link to="/" onClick={() => {if (isMobile()) toggleMenu(); window.location.replace("/#Contact")}}>Contact</Link></li>
                <li className='underline fadeIn'><Link to="/" onClick={() => {if (isMobile()) toggleMenu(); window.location.replace("/#FAQ")}}>FAQ</Link></li>
            </ul>
        </nav>
    </>
}
